<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" style="z-index: 2;">
                            <div class="card-header actions-toolbar border-0">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                        <h2 class="d-inline-block mb-0 card-title">辨識調教清單</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                                <v-col class="px-10">
                                    <v-select
                                        :items="orgItems"
                                        label="事業單位名稱"
                                        v-model="orgId"
                                        outlined
                                        :readonly="loginAuth.role !== 'S'"
                                        style="z-index:999;font-size: 18px;font-weight: bold;"
                                    ></v-select>
                                </v-col>
                                <v-col class="px-10">
                                    <v-select
                                        v-model="jsId"
                                        :items="jobSiteList"
                                        label="場域"
                                        item-text="optionText"
                                        item-value="optionValue"
                                        outlined
                                        style="z-index:999;font-size: 18px;font-weight: bold;"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-10" cols="3">
                                    <v-text-field
                                        v-model="minSimilarity"
                                        label="辨識度起"
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pr-10" cols="3">
                                    <v-text-field
                                        v-model="maxSimilarity"
                                        label="辨識度迄"
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="px-10"  cols="3">
                                    <v-menu
                                        v-model="dateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startDateStr"
                                            label="辨識日期"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="startDateStr"
                                        @input="dateMenu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="pr-10" cols="3">
                                    <v-menu
                                        v-model="dateMenu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="endDateStr"
                                            label="辨識日期"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="endDateStr"
                                        @input="dateMenu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div class="v-data-footer">
                                        <div class="v-data-footer__select">每頁列數:<v-select
                                        v-model="listPageSize"
                                        :items="pageSizeList"
                                        dense
                                        @change="nextPage(1, listPageSize)"
                                        ></v-select></div>
                                        <div class="v-data-footer__pagination">
                                        共 {{pageInfo.totalCount}} 筆資料，全部 {{pageInfo.maxPage}} 頁，目前第{{pageInfo.currentPage}}頁
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage-1, listPageSize)" :disabled="pageInfo.currentPage == 1">mdi-chevron-left</v-icon>
                                        <v-icon class="mx-4" @click="nextPage(pageInfo.currentPage+1, listPageSize)" :disabled="pageInfo.currentPage >= pageInfo.maxPage">mdi-chevron-right</v-icon>
                                        </div>
                                    </div>
                                    <v-data-table
                                    :headers="headers"
                                    :items="datalist"
                                    :items-per-page="listPageSize"
                                    hide-default-footer
                                    disable-sort
                                    class="mx-12"> 
                                        <template v-slot:[`item.similarity`]={item}>
                                            {{item.detectRecord.similarity}}
                                        </template>
                                        <template v-slot:[`item.traning`]={item}>
                                            <v-icon @click.stop="toEdit(item)" >mdi-square-edit-outline</v-icon>
                                        </template>
                                        <template v-slot:[`item.traningId`]={item}>
                                            {{item.detectRecord.trainingId}}
                                        </template>
                                    </v-data-table>
                                    <br/>
                                </v-col>
                            </v-row>
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getInoutDetectRecordList, getOrgnizationList, getJobSiteNameSelectOption} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
        this.authValidate();
        this.auth = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getTokenWithSession(),
            }
        };
  },
  destroyed() {
        clearInterval(this.interval);
  },
  mounted(){
    //console.log("loginAuth" + JSON.stringify(this.loginAuth));
    this.userName = this.loginAuth.memberName;
    this.getOrgDataList();
    if (this.loginAuth.role !== "S") {
        this.orgId = this.loginAuth.orgnization.orgId;
    } 
  },
  computed: {
    headers() {
      return [
          { text: '人員姓名 (編號)', value: 'orgmName', class: "subtitle-2 font-weight-black",},
          { text: '辨識度', value: 'similarity', class: "subtitle-2 font-weight-black", width:80,},
          { text: '辨識日期', value: 'ioTime', class: "subtitle-2 font-weight-black",width:80, },
          { text: '訓練', value: 'traning', class: "subtitle-2 font-weight-black",width:80,},
          { text: '已訓練人員', value: 'traningId', class: "subtitle-2 font-weight-black",width:80,},
      ]
    },
  },
  methods: {
    getOrgDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getOrgnizationList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("response statusCode = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    var datalist = responseData.queryPager.resultList;
                    vm.orgItems = datalist.map(function(item){
                        item.text = item.name;
                        item.value = item.orgId;
                        return item;
                    });
                }
            }
            vm.isLoading = false;
        });
       
    },
    getJSList: function() { 
        var vm = this;
        getJobSiteNameSelectOption(vm.orgId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                console.log("responseData = " + JSON.stringify(responseData));
                if (responseData.statusCode === "1") {
                    vm.jobSiteList = responseData.jobSiteNameSelectOption;
                    
                    // vm.jobSiteList = data.map(function(item) {
                    //     var obj = new Object();
                    //     obj.text = item.jsName;
                    //     obj.value = item.jsId;

                    //     return obj;
                    // });

                    if (vm.jobSiteList.length > 0) {
                        vm.jsId = vm.jobSiteList[0].optionValue;
                    }

                } else  {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
        });
       
    },
    getDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getInoutDetectRecordList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //onsole.log("response = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    vm.datalist = responseData.queryPager.resultList;
                    console.log("datalist = " + JSON.stringify(vm.datalist));

                    vm.datalist = vm.datalist.map(function(item) {
                        item.inoroutCH = item.inorout === "I" ? "入場" : "出場";
                        console.log(JSON.stringify(item.orgnizationMember));
                        if (item.recognizeResult === "N") {
                            item.orgnizationMember = {};
                            item.orgnizationMember.memberPropertyList = [];
                            item.orgmName = item.detectRecord.egroupAiMessage;
                        }
                        return item;
                    });

                    vm.pageInfo.maxPage = responseData.queryPager.maxPage;
                    vm.pageInfo.currentPage = responseData.queryPager.currentPage;
                    vm.pageInfo.totalCount = responseData.queryPager.totalCount;

                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
    keyupDelay: function() {
        //用於延遲查詢，避免value改變時連續送出request
        let vm = this;
        clearTimeout(vm.timer);
        
        vm.timer = setTimeout(function() {
            vm.getDataList();
        }, 800);
    },
    nextPage: function(currentPage, pageSize) {
      //console.log("next page = " + value);
      let vm = this;
      vm.queryRequest.queryPager.pageSize = pageSize;
      vm.queryRequest.queryPager.currentPage = currentPage;
      vm.getDataList();
    },
    toEdit: function(item) {
      this.$router.push({
        path: "/inoutRecord/inoutDetectTraning/" + item.detectRecord.drId
      });
    },
  },
  watch: {
      orgId: function(){
        this.getJSList();
      },
      jsId: function(value){
        this.queryRequest.queryObject.jsId = value;
        this.getDataList();
      },
      inorout: function(value) {
        this.queryRequest.queryObject.inorout = value;
        this.getDataList();
      },
      startDateStr: function(value) {
        this.queryRequest.queryObject.startDateStr = this.formatDate(value);
        this.getDataList();
      },
      endDateStr: function(value) {
        this.queryRequest.queryObject.endDateStr = this.formatDate(value);
        this.getDataList();
      },
      minSimilarity: function(value){
        this.queryRequest.queryObject.minSimilarity = value;
        this.keyupDelay();
      },
      maxSimilarity: function(value){
        this.queryRequest.queryObject.maxSimilarity = value;
        this.keyupDelay();
      },
  },
  data: () => ({
    orgItems: [],
    jobSiteList:[],
    userName: "",
    datalist: [],
    orgId: "",
    jsId: "",
    minSimilarity: "",
    maxSimilarity: "",
    startDateStr: "",
    endDateStr: "",
    isLoading: false,
    dateMenu: null,
    dateMenu2: null,
    auth:{},
    inoroutList:[
        {text:"全部", value:""},
        {text:"入場", value:"I"},
        {text:"出場", value:"O"},
    ],
    pageInfo: {
      maxPage: 1,
      currentPage: 1,
      totalCount: 0,
    },
    listPageSize: 10,
    pageSizeList: [
      {text:"10", value:10},
      {text:"30", value:30},
      {text:"50", value:50},
    ],
    queryRequest: {
        queryObject: {
            jsId: "",
            minSimilarity: "",
            maxSimilarity: "",
            startDateStr: "",
            endDateStr: "",
        },
        queryPager: {
            currentPage: 1,
            pageSize: 10,
        },
    },
    jsQueryRequest: {
        queryObject: {
            jsName: "",
            status: "",
        },
        queryPager: {
            currentPage: 1,
            pageSize: 999,
        },
    },
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>
    
</style>
